import { route } from "preact-router";
import { loginRequest } from "../../authConfig";

export class Context {
    static handleSignIn(loginResponse) {
        var accessToken = loginResponse.accessToken;
        var email = loginResponse.account.username;
        var name = loginResponse.account.name;
        var expiresOn = loginResponse.expiresOn;

        localStorage.setItem("notech_accessToken", accessToken);
        localStorage.setItem("notech_email", email);
        localStorage.setItem("notech_name", name);
        localStorage.setItem("notech_tokenExpiresOn", expiresOn.toISOString());
    }

    static getAccessToken(context) {
        return new Promise(function executor(resolve, reject) {
            var tokenExpiresOn = localStorage.getItem("notech_tokenExpiresOn");

            if (new Date(tokenExpiresOn) > new Date())
                resolve(localStorage.getItem("notech_accessToken"));

            else {
                var ctx = context;
                if (typeof (context.instance) != "undefined")
                    ctx = context.instance;

                return ctx.acquireTokenSilent({
                    ...loginRequest,
                    account: context?.accounts[0]
                }).then((response) => {
                    Context.handleSignIn(response);
                    resolve(localStorage.getItem("notech_accessToken"));
                }).catch((error) => {
                    reject(error);
                });
            }
        });
    }

    static signOut(context) {
        var ctx = context;
        if (typeof (context.instance) != "undefined")
            ctx = context.instance;

        ctx.logout();

        localStorage.removeItem("notech_accessToken");
        localStorage.removeItem("notech_email");
        localStorage.removeItem("notech_name");
        localStorage.removeItem("notech_tokenExpiresOn");
        localStorage.removeItem("notech_referenceDate");
        localStorage.removeItem("no_tech_signintype");

        window.location.reload(true);
    }

    static getUserEmail() {
        return localStorage.getItem("notech_email");
    }

    static getUserName() {
        return localStorage.getItem("notech_name");
    }

    static getShortUserName() {
        var name = localStorage.getItem("notech_name");
        if (name == null)
            return "";
        var nameParts = name.split(" ");
        return nameParts[0][0] + nameParts[nameParts.length - 1][0];
    }

    static getReferenceDate() {
        var date = localStorage.getItem("notech_referenceDate");
        if (date == null) {
            var d = new Date();
            d.setDate(d.getDate() - (d.getDay() + 6) % 7);
            var iso = d.toISOString();
            localStorage.setItem("notech_referenceDate", iso.substring(0, iso.indexOf('T')));
            return d;
        }
        return date;
    }

    static setReferenceDate(d) {
        var iso = d.toISOString();
        localStorage.setItem("notech_referenceDate", iso.substring(0, iso.indexOf('T')));
        var event = new CustomEvent('referenceDateChanged', {
            bubbles: true,
            detail: {
                date: new Date(d),
                weekNumber: Context.getWeekNumber(d)
            }
        });

        window.dispatchEvent(event);
    }
 
    static getWeekNumber(date) {		
		var startDate = new Date(date.getFullYear(), 0, 1);
		var days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
		return Math.ceil(days / 7);
	}

}