import { Router } from 'preact-router';
import { Context } from './context/context';
import AuthComponent from '../componentWithAuthContext';
import AfterRedirect from './afterRedirectSignIn';
import UserHoursRoute from '../routes/UserHoursRoute';
import EmployeeRoute from '../routes/Employee';

export default class AuthenticatedApp extends AuthComponent {
    state = {
        weekNumber : Context.getWeekNumber(new Date(localStorage.getItem("notech_referenceDate"))),
        selectedEmployee: "",
        selectedEmployeeId: "",
        isMenuOpen: false,
        isUserMenuOpen: false,
        isEmployeeRoute: window.location.toString().indexOf("/employee/") > -1,
        breadCrumbs: [
            {
                url: '/',
                name: 'Uren controleren'
            }
        ]
    }

    componentDidMount(){
        window.addEventListener('referenceDateChanged', (e) => {
            this.setState({
                weekNumber: e.detail.weekNumber
            });
        });

        window.addEventListener('selectedEmployeeChanged', (e) => {
            this.setState({
                selectedEmployee: e.detail.name,
                selectedEmployeeId: e.detail.id
            });
        });
    }

    toggleMenu = () => {
        this.setState({
            isMenuOpen: !this.state.isMenuOpen
        });
    }

    toggleUserMenu = () => {    
        this.setState({
            isUserMenuOpen: !this.state.isUserMenuOpen
        });
    }

    signOut = (e) =>{
        e.preventDefault(); 
        Context.signOut(this.context);
    }

    handleRouteChange = (e) => {
        this.setState({
            isEmployeeRoute: e.url.indexOf("/employee/") > -1
        });
    }

    render({ }, { isMenuOpen, isUserMenuOpen, weekNumber, selectedEmployee, selectedEmployeeId, isEmployeeRoute }) {       
        return (
            <div id="app">
                <nav class="bg-gray-50">
                    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div class="relative flex h-16 items-center justify-between border-b border-gray-200">
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <a href="/">
                                        <img class="h-8 w-auto" src="/assets/img/notech_s-tr.png" alt="Logo NoTech" />
                                    </a>
                                </div>
                                <div class="hidden lg:ml-10 lg:block">
                                    <div class="flex space-x-4">
                                        <a href="/" class="bg-gray-100 px-3 py-2 rounded-md text-sm font-medium text-gray-900" aria-current="page">Uren controleren</a>
                                    </div>
                                </div>
                            </div>

                            <div class="flex lg:hidden">
                                <button onClick={this.toggleMenu} type="button" class="inline-flex items-center justify-center rounded-md bg-gray-50 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ntech-100 focus:ring-offset-2 focus:ring-offset-gray-50" aria-controls="mobile-menu" aria-expanded="false">
                                    <span class="sr-only">Open main menu</span>

                                    <svg class={(isMenuOpen ? "hidden" : "block") + " h-6 w-6"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
                                    </svg>

                                    <svg class={(isMenuOpen ? "block" : "hidden") + " h-6 w-6"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </div>


                            <div class="hidden lg:ml-4 lg:block">
                                <div class="flex items-center">

                                    <div class="relative ml-3 flex-shrink-0">
                                        <div>
                                            <button onClick={this.toggleUserMenu} type="button" class="flex rounded-full bg-gray-50 text-sm text-white focus:outline-none focus:ring-2 focus:ring-ntech-100 focus:ring-offset-2 focus:ring-offset-gray-50" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                                <span class="sr-only">Open user menu</span>
                                                <div class="h-10 w-10 flex-shrink-0 rounded-full bg-ntech-100 text-ytech-100 font-bold flex justify-center items-center">
                                                    {Context.getShortUserName()}
                                                </div>
                                            </button>
                                        </div>

                                        <div class={(isUserMenuOpen ? "block" : "hidden") + "  absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"} role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                                            <a href="#" onClick={this.signOut} class="block py-2 px-4 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Uitloggen</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class={(isMenuOpen ? "block" : "hidden") + " border-b border-gray-200 bg-gray-50 lg:hidden"}>
                        <div class="space-y-1 px-2 pt-2 pb-3">

                            <a href="/" class="bg-gray-100 block px-3 py-2 rounded-md font-medium text-gray-900" aria-current="page">Uren controleren</a>

                        </div>
                        <div class="border-t border-gray-200 pt-4 pb-3">
                            <div class="flex items-center px-5">
                                <div class="flex-shrink-0">
                                    <div class="h-10 w-10 flex-shrink-0 rounded-full bg-ntech-100 text-ytech-100 font-bold flex justify-center items-center">{Context.getShortUserName()}</div>
                                </div>
                                <div class="ml-3">
                                    <div class="text-base font-medium text-gray-800">{Context.getUserName()}</div>
                                    <div class="text-sm font-medium text-gray-500">{Context.getUserEmail()}</div>
                                </div>
                                <button type="button" class="ml-auto flex-shrink-0 rounded-full bg-gray-50 p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ntech-100 focus:ring-offset-2 focus:ring-offset-gray-50"></button>
                            </div>
                            <div class="mt-3 space-y-1 px-2">
                                <a href="#" onClick={this.signOut} class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100">Uitloggen</a>
                            </div>
                        </div>
                    </div>
                </nav>
                <header class="bg-gray-50 py-4">
                    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                        <div class="min-w-0 flex-1">
                            <nav class="flex" aria-label="Breadcrumb">
                                <ol role="list" class="flex items-center space-x-4">
                                    <li>
                                        <div>
                                            <a href="/" class="text-sm font-medium text-gray-500 hover:text-gray-700">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-gray-500" width="16" height="16" viewBox="0 0 24 24"><path d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z"></path></svg>
                                            </a>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="flex items-center">                                 
                                            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd"></path>
                                            </svg>

                                            <a href="/" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Week-{weekNumber}</a>
                                        </div>
                                    </li>

                                   {!isEmployeeRoute ? "" : 
                                        <li>
                                           <div class="flex items-center">                                 
                                               <svg class="h-5 w-5 flex-shrink-0 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                   <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd"></path>
                                               </svg>
   
                                               <a href={"/employee/"+selectedEmployeeId} class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{selectedEmployee}</a>
                                           </div>
                                       </li>
                                    }
                                   
                                </ol>
                            </nav>
                        </div>
                    </div>
                </header>             
                <Router onChange={this.handleRouteChange}>
                    <UserHoursRoute path="/" />
                    <EmployeeRoute path="/employee/:userId"  />
                </Router>
                <AfterRedirect />
            </div>
        )
    }
}

